
import {Box, Collapse, IconButton, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import {
    Check,
    DeleteOutlined,
    DynamicFeed, Edit,
    ExpandLess,
    ExpandMore
} from "@mui/icons-material";
import React, {Fragment, useEffect, useState} from "react";
import {currencyFormat, dateFormat} from "../../common/formatters/format";


const Row = ({ props, desagrupar, eliminar, editar, check}) => {

    const [data, setData] = useState(props);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setData(props);
    },[props])

    const desagruparFactura = (consecutivo, row) =>{
        desagrupar(consecutivo, row);
        setData(props);
    }

    const eliminarFactura = (esPadre, consectivo, row) => {
        eliminar(esPadre, consectivo, row);
        setData(props);
    }

    const editarFactura = (esPadre, consecutivo, row) => {
        editar(esPadre, consecutivo, row);
        setData(props);
    }

    const checkFactura = (esPadre, consectivo, row) => {
        check(esPadre, consectivo, row);
        setData(props);
    }

    const [monto, setMonto] = React.useState();

    function handleChange(event) {
        event.preventDefault();
        const re = /^[1-9][.\d]*(,\d+)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            data.montoUpdated = event.target.value
            setMonto(event.target.value)
        }
    }

    return (
        <Fragment>
            <TableRow >
                <TableCell sx={{width: 50, maxWidth: 50}}>
                    {data?.agrupados?.length === 0 ? <></> : <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>}
                </TableCell>
                <TableCell align="left" sx={{width: 50}}>{data?.folioFactura}</TableCell>
                <TableCell align="left" sx={{width: 250}}>{data?.proveedor}</TableCell>
                <TableCell align="left" sx={{width: 150}}>{data?.rfc}</TableCell>
                <TableCell align="left" sx={{width: 100}}>{dateFormat(data?.fechaProgramadaPago)}</TableCell>
                <TableCell align="left" sx={{width: 50}}>{data?.moneda}</TableCell>
                <TableCell align="right" sx={{width: 150}}>
                    {data?.agrupados?.length === 0 && data?.editar ?
                        <TextField name={"monto"} id={"monto"} defaultValue={data?.monto} value={monto} onChange={handleChange} size={"small"} sx={{maxWidth: 100}} ></TextField>
                        : currencyFormat(data?.monto)
                    }
                </TableCell>
                <TableCell sx={{width: 50}}>
                    {data?.agrupados?.length === 0 ?
                        <>
                            { data?.editar ?
                                <Check fontSize={'small'} cursor={'pointer'} onClick={() => checkFactura(true, data?.consecutivo, [])}/>
                                :
                                <Edit fontSize={'small'} cursor={'pointer'} onClick={() => editarFactura(true, data?.consecutivo, [])}/>
                            }
                        </>
                        :
                        <></>
                    }

                </TableCell>
                <TableCell sx={{width: 50}}>
                    <DeleteOutlined fontSize={'small'} cursor={'pointer'} onClick={() => eliminarFactura(true, data?.consecutivo, [])}/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width={'100%'} style={{ padding:0}} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data?.agrupados?.map((agrupadoRow) => (
                                        <TableRow key={agrupadoRow.id}>
                                            <TableCell sx={{width: 50, maxWidth: 50}}>
                                                    <DynamicFeed cursor={'pointer'} fontSize={'small'} onClick={() => desagruparFactura(data?.consecutivo, agrupadoRow)}/>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {agrupadoRow.folioFactura}
                                            </TableCell>

                                            <TableCell />
                                            <TableCell/>
                                            <TableCell />
                                            <TableCell/>
                                            <TableCell align="right">
                                                {agrupadoRow?.editar === true ?
                                                    <TextField name={"monto"} id={"monto"} defaultValue={agrupadoRow.monto} onChange={handleChange} size={"small"} sx={{maxWidth: 100}} ></TextField>
                                                    :
                                                    currencyFormat(agrupadoRow.monto)
                                                }
                                            </TableCell>
                                            <TableCell sx={{width: 50}}>
                                                {agrupadoRow?.editar ?
                                                    <Check fontSize={'small'} cursor={'pointer'} onClick={() => checkFactura(false, data?.consecutivo, agrupadoRow)}/>
                                                    :
                                                    <Edit fontSize={'small'} cursor={'pointer'} onClick={() => editarFactura(false, data?.consecutivo, agrupadoRow)}/>
                                                }
                                            </TableCell>
                                            <TableCell sx={{width: 50}}>
                                                <DeleteOutlined fontSize={'small'} cursor={'pointer'} onClick={() => eliminarFactura(false, data?.consecutivo, agrupadoRow)}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export default Row;